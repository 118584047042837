.hoverGrow:hover {
    transform: scale(1.2) translate(0, -5%);
}
.hoverGrowCluster:hover {
    transform: scale(1.2);
}
.hoverHref:hover {
    transform: scale(1.1);
    cursor: pointer;
}
