.filter_buttons_container {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    -webkit-box-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 0.5rem;
    min-height: 2.25rem;
    overflow: hidden;
    padding: 0.125rem;
    margin: -0.125rem;
    -webkit-flex: 1;
    -ms-flex: 1;
  }
  
  .button_icon {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    gap: 0.375rem;
    height: 1.375rem;
    margin: 0 auto;
  }
  
  .button_text {
    margin: 0;
    font-size: 0.65625rem;
    text-transform: none;
    font-weight: 400;
    line-height: 1.66;
    color: #566573;
    height: 0.875rem;
  }
  
  .default_button_text {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    gap: 0.375rem;
    height: 1rem;
    margin: 0 auto;
    font-weight: 500;
    font-size: 0.875rem;
  }
  
  .inner_button_wrapper {
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }
  
  .filter_button {
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-weight: 500;
    text-transform: none;
    font-size: 0.9375rem;
    font-family: Inter,sans-serif;
    line-height: 1.75;
    border-radius: 3px;
    padding: 15px;
    border: 1px solid rgba(103, 103, 102, 0.2);
    color: #566573;
    border-color: transparent;
    color: #566573;
    height: 1.875rem;
    padding-inline: 0.75rem;
    padding-block: 0;
    color: #252c33;
    font-size: 1.125rem;
    font-weight: 300;
    height: 3rem;
    min-width: 3.125rem;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-color: rgba(103, 103, 102, 0.2);
    background-color: #fff;
    height: 3rem;
    height: 3rem;
    min-width: 3.125rem;
  }
  
  .filter_button:hover {
    background-color: rgba(103, 103, 102, 0.15);
  }
  
  .active {
    background-color: rgba(100, 174, 248, 0.1);
    border: 0.0625rem solid #085db3;
    color: #106ecc;
  }