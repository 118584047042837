.clusterSpan {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(60, 60, 60);
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.okCluster {
  background-image: url(/src/attributes/svgMarkers/cluster_ok.svg);
}

.warningCluster {
  background-image: url(/src/attributes/svgMarkers/cluster_warning.svg);
}

.infoCluster {
  background-image: url(/src/attributes/svgMarkers/cluster_info.svg);
}

.errorCluster {
  background-image: url(/src/attributes/svgMarkers/cluster_error.svg);
}

.offlineCluster {
  background-image: url(/src/attributes/svgMarkers/cluster_offline.svg);
}/*# sourceMappingURL=cluster.css.map */