:root {
    --yunexSteel: #688aba;
    --yunexGray: #e4eded;
    --yunexWhite: #ffffff;
    --yunexSky: #deecff;
    --yunexPistachio: #affad7;
    --lightSeeThrough: rgba(245, 244, 242, 0.8);
    --darkSeeThrough: rgba(37, 44, 51, 0.8);
    --yunexSpringGradient: linear-gradient(
        90deg,
        var(--yunexSky) 25%,
        var(--yunexGray) 75%,
        var(--yunexPistachio) 95%
    );
}