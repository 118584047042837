.popup_property_box {
    display: flex;
    gap: 1rem;
    span {
        color: white
    }& span:nth-child(1) {
        color: #adbdcc;
    }
}

.mapcontainer {
    height: Calc(100vh - 56px);
    width: 100%;
}
