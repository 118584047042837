.popover {
  top: 50px !important;
  border-radius: 0;
  z-index: 1000;
  height: 100vh;
}

.popover > div > div {
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 100vh;
}

.display_field__border {
  border-bottom: 1px solid rgba(103, 103, 102, 0.2) !important;
}

.divider {
  margin-top: 24px;
  width: 100%;
  border-bottom: 2px solid rgba(103, 103, 102, 0.2);
}

.dialog_content__padding {
  padding-top: 0 !important;
}

.title {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  // padding-bottom: 1rem !important;
  padding-left: 0.75rem !important;
}

.dialog_subtitle {
  color: #566573 !important;
  font-weight: 500 !important;
  font-size: 0.85rem !important;
  padding-bottom: 1rem !important;
}