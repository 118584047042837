.block {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.first_block {
  padding-top: 1rem;
  padding-bottom: 1rem;
  > div {
    padding-top: 0;
  }
}
